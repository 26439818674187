import React from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import {WatchlistStock } from '../types/types';
import TableStockRow from './TableStockRow';

const WatchlistStocksTable: React.FC<{ watchlistStocks: WatchlistStock[], onDelete: (stockId: number) => void }> = ({ watchlistStocks, onDelete}) => {

    
    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>
                Watchlist Stocks
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="watchlist stocks table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ticker</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {watchlistStocks.map((watchlistStock) => (
                            <TableStockRow key={watchlistStock.stock_id} watchlistStock={watchlistStock} onDelete={onDelete} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default WatchlistStocksTable;


import './App.css';
import SearchStocks from './components/SearchStock';


function App() {
  return (
    <div className="App">
     <SearchStocks></SearchStocks>
    </div>
  );
}

export default App;

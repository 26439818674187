import axios from 'axios';
import { Stock } from '../types/types';

const baseURL = 'https://stocks-watchlist-js.jcr-reis3778.workers.dev';

// Stocks Service
export const getStocks = async () => {
    try {
        const response = await axios.get(`${baseURL}/stocks`);
        return response.data;
    } catch (error) {
        console.error('Error fetching stocks:', error);
        throw error;
    }
};

export const createStock = async (stockData: Partial<Stock>) => {
    try {
        await axios.post(`${baseURL}/stocks`, stockData);
    } catch (error) {
        console.error('Error creating stock:', error);
        throw error;
    }
};

export const getStockById = async (id: number) => {
    try {
        const response = await axios.get(`${baseURL}/stocks/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching stock with ID ${id}:`, error);
        throw error;
    }
};

export const updateStock = async (id: number, stockData: Partial<Stock>) => {
    try {
        await axios.put(`${baseURL}/stocks/${id}`, stockData);
    } catch (error) {
        console.error(`Error updating stock with ID ${id}:`, error);
        throw error;
    }
};

export const deleteStockFromWatchlist = async (watchlistId: number, stockId: number) => {
    try {
        await axios.delete(`${baseURL}/watchlist-stocks/${watchlistId}/${stockId}`);
    } catch (error) {
        console.error(`Error deleting stock with ID ${stockId} from watchlist with ID ${watchlistId}:`, error);
        throw error;
    }
};

export const deleteStock = async (id: number) => {
    try {
        await axios.delete(`${baseURL}/stocks/${id}`);
    } catch (error) {
        console.error(`Error deleting stock with ID ${id}:`, error);
        throw error;
    }
};

export const getWatchlists = async () => {
    try {
        const response = await axios.get(`${baseURL}/watchlists`);
        return response.data;
    } catch (error) {
        console.error('Error fetching watchlists:', error);
        throw error;
    }
};

export const createWatchlist = async (name: string) => {
    try {
        await axios.post(`${baseURL}/watchlists`, { name });
    } catch (error) {
        console.error('Error creating watchlist:', error);
        throw error;
    }
};

export const getWatchlistById = async (id: number) => {
    try {
        const response = await axios.get(`${baseURL}/watchlists/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching watchlist with ID ${id}:`, error);
        throw error;
    }
};

export const updateWatchlist = async (id: number, name: string) => {
    try {
        await axios.put(`${baseURL}/watchlists/${id}`, { name });
    } catch (error) {
        console.error(`Error updating watchlist with ID ${id}:`, error);
        throw error;
    }
};

export const deleteWatchlist = async (id: number) => {
    try {
        await axios.delete(`${baseURL}/watchlists/${id}`);
    } catch (error) {
        console.error(`Error deleting watchlist with ID ${id}:`, error);
        throw error;
    }
};

// Watchlist Stocks Service
export const getWatchlistStocks = async () => {
    try {
        const response = await axios.get(`${baseURL}/watchlist-stocks`);
        return response.data;
    } catch (error) {
        console.error('Error fetching watchlist stocks:', error);
        throw error;
    }
};

export const addStockToWatchlist = async (watchlist_id: number, stock_id: number) => {
    try {
        await axios.post(`${baseURL}/watchlist-stocks`, { watchlist_id, stock_id });
    } catch (error) {
        console.error('Error adding stock to watchlist:', error);
        throw error;
    }
};

export const getWatchlistStocksById = async (watchlist_id: number) => {
    try {
        const response = await axios.get(`${baseURL}/watchlist-stocks/${watchlist_id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching watchlist stocks for watchlist ID ${watchlist_id}:`, error);
        throw error;
    }
};

export const deleteWatchlistStocks = async (watchlist_id: number) => {
    try {
        await axios.delete(`${baseURL}/watchlist-stocks/${watchlist_id}`);
    } catch (error) {
        console.error(`Error deleting watchlist stocks for watchlist ID ${watchlist_id}:`, error);
        throw error;
    }
};

export const getPortfolios = async () => {
    try {
        const response = await axios.get(`${baseURL}/portfolios`);
        return response.data;
    } catch (error) {
        console.error('Error fetching portfolios:', error);
        throw error;
    }
};

export const createPortfolio = async (name: string) => {
    try {
        await axios.post(`${baseURL}/portfolios`, { name });
    } catch (error) {
        console.error('Error creating portfolio:', error);
        throw error;
    }
};

export const getPortfolioById = async (id: number) => {
    try {
        const response = await axios.get(`${baseURL}/portfolios/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching portfolio with ID ${id}:`, error);
        throw error;
    }
};

export const updatePortfolio = async (id: number, name: string) => {
    try {
        await axios.put(`${baseURL}/portfolios/${id}`, { name });
    } catch (error) {
        console.error(`Error updating portfolio with ID ${id}:`, error);
        throw error;
    }
};

export const deletePortfolio = async (id: number) => {
    try {
        await axios.delete(`${baseURL}/portfolios/${id}`);
    } catch (error) {
        console.error(`Error deleting portfolio with ID ${id}:`, error);
        throw error;
    }
};

// Portfolio Stocks Service
export const getPortfolioStocks = async () => {
    try {
        const response = await axios.get(`${baseURL}/portfolio-stocks`);
        return response.data;
    } catch (error) {
        console.error('Error fetching portfolio stocks:', error);
        throw error;
    }
};

export const addStockToPortfolio = async (portfolio_id: number, stock_id: number, shares: number, average_cost: number) => {
    try {
        await axios.post(`${baseURL}/portfolio-stocks`, { portfolio_id, stock_id, shares, average_cost });
    } catch (error) {
        console.error('Error adding stock to portfolio:', error);
        throw error;
    }
};

export const getPortfolioStocksById = async (portfolio_id: number) => {
    try {
        const response = await axios.get(`${baseURL}/portfolio-stocks/${portfolio_id}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching portfolio stocks for portfolio ID ${portfolio_id}:`, error);
        throw error;
    }
};

export const deletePortfolioStocks = async (portfolio_id: number) => {
    try {
        await axios.delete(`${baseURL}/portfolio-stocks/${portfolio_id}`);
    } catch (error) {
        console.error(`Error deleting portfolio stocks for portfolio ID ${portfolio_id}:`, error);
        throw error;
    }
};
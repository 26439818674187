import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Stock, WatchlistStock } from '../types/types'
import { addStockToWatchlist } from '../services/services';
import WatchlistStocksTable from './WatchlistTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const StockDetails = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;

  @media (max-width: 600px) {
    padding: 15px;
    font-size: 14px;
  }
`;

const AddToWatchlistButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const WatchlistContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 600px;
`;


const SearchStocks: React.FC = () => {
    const [ticker, setTicker] = useState<string>('');
    const [stock, setStock] = useState<Stock | null>(null);
    const [error, setError] = useState<string>('');

    const [watchlistStocks, setWatchlistStocks] = useState<WatchlistStock[]>([]);
    const watchlistId = 1; // Replace with your actual watchlist ID


    const fetchWatchlistStocks = async () => {
        try {
            const response = await axios.get<any>(`https://stocks-watchlist-js.jcr-reis3778.workers.dev/watchlist-stocks/${watchlistId}`);
            const watchListStocks: WatchlistStock[] = response.data.results
            setWatchlistStocks(watchListStocks)
        } catch (e) {
            setError('Error fetching watchlist stocks.');
            console.error(e);
        }
    };

    const handleSearch = async () => {
        console.log(watchlistStocks)
        try {
            setError('');
            setStock(null);
            const response = await axios.get<Stock[]>(`https://stocks-watchlist-js.jcr-reis3778.workers.dev/stocks?ticker=${ticker}`);
            const results = response.data;
            if (results.length > 0) {
                setStock(results[0]);
            } else {
                setError('No stock found with that ticker.');
            }
        } catch (e) {
            setError('Error fetching stock.');
            console.error(e);
        }
    };

    const handleDelete = (stockId: number) => {
      setWatchlistStocks((prevStocks) => prevStocks.filter(stock => stock.stock_id !== stockId));
    };


    const handleAddToWatchlist = async () => {
        if (stock) {
            try {
                const response = await addStockToWatchlist(watchlistId, stock.id)
                console.log(response)
                setWatchlistStocks([...watchlistStocks, {watchlist_id: watchlistId, stock_id: stock.id}])
            } catch (e) {
                setError('Error adding stock to watchlist.');
                console.error(e);
            }
        }
    };

    useEffect(() => {
        fetchWatchlistStocks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Container>
            <h1>Search Stocks</h1>
            <SearchInput
                type="text"
                value={ticker}
                onChange={(e) => setTicker(e.target.value.toUpperCase())}
                placeholder="Enter stock ticker"
            />
            <SearchButton onClick={handleSearch}>Search</SearchButton>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {stock && (
                <StockDetails>
                    <p><strong>Ticker:</strong> {stock.ticker}</p>
                    <p><strong>Company Name:</strong> {stock.company_name}</p>
                    <p><strong>Description:</strong> {stock.description}</p>
                    <p><strong>Website:</strong> <a href={stock.website} target="_blank" rel="noopener noreferrer">{stock.website}</a></p>
                    <AddToWatchlistButton onClick={handleAddToWatchlist}>Add to Watchlist</AddToWatchlistButton>
                </StockDetails>
            )}
            <WatchlistContainer>
              <WatchlistStocksTable watchlistStocks={watchlistStocks} onDelete={handleDelete} />
            </WatchlistContainer>
        </Container>
    );
};

export default SearchStocks;
import React, { useEffect, useState } from 'react';
import { TableCell, TableRow, Button } from '@mui/material';
import { Stock, WatchlistStock } from '../types/types';
import { getStockById, deleteStockFromWatchlist } from '../services/services';

const TableStockRow: React.FC<{ watchlistStock: WatchlistStock; onDelete: (stockId: number) => void }> = ({ watchlistStock, onDelete }) => {
    const [stock, setStock] = useState<Stock | undefined>(undefined);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const fetchStock = async () => {
            try {
                const stockData = await getStockById(watchlistStock.stock_id);
                setStock(stockData);
            } catch (error) {
                setError('Error fetching stock information.');
                console.error(error);
            }
        };

        fetchStock();
    }, [watchlistStock.stock_id]);

    const handleDelete = async () => {
        try {
            await deleteStockFromWatchlist(watchlistStock.watchlist_id, watchlistStock.stock_id);
            onDelete(watchlistStock.stock_id);
        } catch (error) {
            setError('Error deleting stock from watchlist.');
            console.error(error);
        }
    };

    return (
        <TableRow key={stock?.id}>
            {error && <TableCell colSpan={3} style={{ color: 'red' }}>{error}</TableCell>}
            {stock ? (
                <>
                    <TableCell component="th" scope="row">
                        {stock.ticker}
                    </TableCell>
                    <TableCell>{stock.description}</TableCell>
                    <TableCell>
                        <Button variant="contained" color="secondary" onClick={handleDelete}>
                            Delete
                        </Button>
                    </TableCell>
                </>
            ) : (
                <TableCell colSpan={3}>Loading...</TableCell>
            )}
        </TableRow>
    );
};

export default TableStockRow;
